<template>
    <div class="popup_overlay">
    <div class="custom_dialog " style="width:600px;max-height: 600px;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-2">
                Selfie
            </div>
            <div class="flex items-center">
              <div class=" text-error heading-3 cursor-pointer bg-white px-2 py-0.5 rounded-xl" @click="isDeleteSelfi = true">
                <i class="fa-solid fa-trash"></i>
              </div >
              <div class=" text-primary heading-3 cursor-pointer bg-white px-2.5 py-0.5 rounded-xl ml-2" @click="discard(false)">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
          <div class="p-2 flex justify-center">
            <img :style="`width: auto;max-height:50vh`" class=" w-auto" :src="srcUrl" alt="">
          </div>
          <div class="text-center py-3">
            {{fileObject.recordedTime | dateToHowManyAgo}}
          </div>
        </div>
        </div>
    </div>
    <ConfirmBox v-if="isDeleteSelfi"  :message="'Once deleted cannot undone'" :title="'Are you sure to delete this selfie?'" />
  </div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue';
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    ConfirmBox
  },
  data () {
    return {
      srcUrl: '',
      isDeleteSelfi: false,
      imageDivHeight: window.innerHeight ,
      imageDivHeight2: window.innerHeight - 140
    }
  },
  props: ["fileObject"],
   created() {
  },
  mounted () {
      this.srcUrl = this.fileObject.selfiePath
      document.body.style = 'overflow: hidden;'
      this.$root.$on('confirmBoxHandler', (response) => {
      document.body.style = 'overflow: visible;'
        if (response&& this.isDeleteSelfi) {
          this.deleteSelfie()
        }
        this.isDeleteSelfi = false
    })
  },
  watch: {},
  methods: {
    discard (data) {
      this.$root.$emit('fullScreenSelfies', data)
      document.body.style = 'overflow: visible;'
    },
    deleteSelfie () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.deleteSelfie(
        this.fileObject.fileVaultId,
        response => {
          this.isDeleteSelfi = false
          this.discard(true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }) 
    },
  },
  beforeDestroy () {
    this.$root.$off('confirmBoxHandler')
  }
}
</script>
<style scoped>
.imageFullScreen {
  width: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
}
</style>