<template>
  <div v-if="custDetails !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-between">
        <div class="flex justify-center items-center">
        <h1 class="heading-1 text-center">{{ custDetails.firstName }} {{ custDetails.lastName }}</h1>
      </div>
      <div class=" text-error heading-4 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="removeConfirm = true">
          <i class="fa-solid fa-trash"></i>
        </div>
      </div>
    </div>
    <div>
      <div class="lg:grid grid-cols-5 gap-4">
        <div class="col-span-2 my-4">
          <div class="card  bg-white rounded-xl text-center p-3 mb-4" v-if="showBreakIsOn">
            <div class="heading-3 text-error">Shift Paused</div>
            <div class="heading-5 text-text2">{{ breakTitleVal }}</div>
            <div class="heading-6 text-text2">{{ breakNoteVal }}</div>
          </div>
          <div class="card  bg-white rounded-xl text-center p-4 mb-4" :class="showBreakIsOn ? 'opacity-50' : ''">
            <div class="text-text2 heading-4">
              <span>{{ displayText }}</span><span class="pl-2 text-gray4 heading-6"
                v-if="custDetails.isForceClockOut">(Forced clocked out)</span>
            </div>
            <div class="mt-2">
              <p class="text-text2 heading-1">{{ totalTimeValue | ConvertMinutesInHoursAndMinutes }}</p>
            </div>
            <p class="text-gray4 heading-6 mt-2">Excluding breaks</p>
            <div v-if="custDetails.selfieList !== null" class="">
              <div class="flex gap-4 justify-between" style="flex-wrap: wrap;">
                <div class=" card rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer "
                  style="width: 80px; height: 80px" v-for="(attach, a) in custDetails.selfieList " :key="a"
                  @click="showFullImage(attach)">
                  <div class="text-center">
                    <img style="height: 60px;width: 120px;object-fit: contain;" :src="attach.selfiePath"
                      class="scroll-img-view">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card  bg-white rounded-xl p-4 mb-4">
            <div class="">
              <div class="text-text2 heading-4 flex items-center justify-between" :class="TotalBreak > 0 ? '' : ''">
                <span>Breaks Taken</span>
                <div @click="openBreadkDetail = !openBreadkDetail"
                  class="pl-2 text-primary cursor-pointer heading-4 flex items-center" v-if="custDetails.breakList.length > 0">
                  <span>{{ TotalBreak | ConvertMinutesInHoursAndMinutes }}</span>
                  <span v-if="!openBreadkDetail" class="text-primary pl-2"><i class="fas fa-chevron-down"></i></span>
                  <div v-if="openBreadkDetail" class="text-primary pl-2"><i class="fas fa-chevron-up"></i></div>
                </div>
                <span class="pl-2 text-text2 heading-4" v-if="custDetails.breakList.length === 0">No Break</span>
              </div>
              <div class="" v-if="openBreadkDetail">
                <div class="bg-white card rounded p-2 m-4 mt-0 mb-1" v-for="(data, index) in custDetails.breakList" :key="index">
                  <div v-if="data.breakEndTime !== ''">
                    <div class="text-right text-gray4 heading-5">{{ data.displayText }}</div>
                    <div class="flex items-start justify-between">
                      <div>
                        <p class="text-text2 heading-4 pl-2">{{ data.breakTitle === '' ? '-' : data.breakTitle }}</p>
                        <p class="text-text2 heading-6 pl-2" v-if=" data.notes !== ''">{{data.notes}}</p>
                      </div>
                      <p class="text-gray4">{{ data.totalTimeInMin | ConvertMinutesInHoursAndMinutes }}</p>
                    </div>
                  </div>
                  <div v-if="data.breakEndTime === ''">
                    <div class="text-right text-gray4 heading-5">{{ data.breakStartTime | getStringData}}</div>
                    <div class="flex items-start justify-between">
                      <div>
                        <p class="text-text2 heading-4 pl-2">{{ data.breakTitle === '' ? '-' : data.breakTitle }}</p>
                        <p class="text-text2 heading-6 pl-2" v-if=" data.notes !== ''">{{data.notes}}</p>
                      </div>
                      <p class="text-secondary">Ongoing Break</p>
                    </div>
                  </div>
                  <div v-if="data.selfieList !== null">
                    <div class="flex gap-4" style="flex-wrap: wrap;">
                      <div class=" card rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer"
                        style="width: 60px; height: 60px" v-for="(attach, a) in data.selfieList " :key="a"
                        @click="showFullImage(attach)">
                        <div class="text-center">
                          <img style="height: 50px;width: 100px;object-fit: contain;" :src="attach.selfiePath"
                            class="scroll-img-view">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card  bg-white rounded-xl  p-4 mb-1">
            <span class=" text-text2 heading-4">Location:</span>
            <div class="relative pt-2">
              <gmap-map ref="gmap" :center="center" :zoom="11" map-type-id="satellite" :options="{
                scrollwheel: true,
                zoomControl: true,
                tilt: 0,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true
              }" style="width: 100%;height: 50vh;">
                <GmapMarker :position="center" :clickable="true">
                  <gmap-polyline v-for="(data, index) in locationArr" :key="index" :path="data" ref="polyline"
                    :options="{ strokeColor: '#FF0000' }"></gmap-polyline>
                </GmapMarker>
              </gmap-map>
            </div>
          </div>
        </div>
        <div class="col-span-3 my-4" v-if="canNotePermission">
          <div class="card rounded-xl bg-white mb-4 p-4">
            <NotesList :detailId="parseInt(this.$route.params.shiftId)" :requestType="'shift'" :noteTitle="'Shift Note'" />
          </div>
        </div>
        <div class="col-span-3 my-4" v-else>
          <div class="card rounded-xl bg-white mb-4 p-4">
            <p class="heading-6 text-gray4">You Dont have permission to show Shift Note</p>
          </div>
        </div>
      </div>
    </div>
    <FullImage v-if="shoFullImage" :fileObject="fileObject" />
    <div v-if="removeConfirm" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="removeConfirm = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteShift"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global google */
import Button from '@/View/components/globalButton.vue'
import {FilterPermissions} from '@/utils/Permissions.js'
import {getDisplayText} from '@/utils/convertDateAndTime.js'
import NotesList from '@/View/job/visit/NotesList.vue';
import FullImage from './FullImage.vue'
import Vue from 'vue'
import store from '@/store'
import * as VueGoogleMaps from 'vue2-google-maps'
var moment = require('moment')
import MyJobApp from "@/api/MyJobApp.js";
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  name: "customer",
  components: {
    Button,
    FullImage,
    NotesList
  },
  data() {
    return {
      showBreakIsOn: false,
      breakTitleVal: '',
      breakNoteVal: '',
      canNotePermission: FilterPermissions('shift_notes'),
      removeConfirm: false,
      pathMarker: [],
      flightPath: [],
      userLocation: [],
      center: { lat: 18.60215033927151, lng: 73.75374753105622 },
      activeIndex: 0,
      openBreadkDetail: false,
      totalTimeValue: 0,
      fileObject: {},
      shoFullImage: false,
      displayText: '',
      locationArr: [],
      TotalBreak: 0,
      contactDetailForUpdate: null,
      deleteSelectId: null,
      updatesContact: false,
      delteAddressConfirm: false,
      updateCompanyName: false,
      addAddress: false,
      custDetails: null,
    };
  },
  created() { },
  mounted() {
    this.getLocationDetail()
    this.getCustDetails()
    this.$root.$on('fullScreenSelfies', (response) => {
      if (response) {
        this.getCustDetails()
        this.shoFullImage = false
      }
      document.body.style = 'overflow: visible;'
      this.shoFullImage = false
    })
  },
  methods: {
    updateContact(detail) {
      this.updatesContact = true
      this.contactDetailForUpdate = detail
    },
    getLocationDetail() {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.getSpecificShiftLocation(
        parseInt(this.$route.params.shiftId),
        (response) => {
          this.locationArr = []
          let tempArr = []
          this.userLocation = response.Data !== null ? response.Data : []
          for (let index = 0; index < this.userLocation.length; index++) {
            let data = []
            tempArr.push(this.userLocation[index].locationList)
            console.log('this.userLocation[index].locationList', this.userLocation[index].locationList)
            let list = this.userLocation[index].locationList
            for (let i = 0; i < list.length; i++) {
              let locationObj = JSON.parse(list[i].locationLogs)
              console.log('locationObj', locationObj.locationLogs)
              for (let JJ = 0; JJ < locationObj.locationLogs.length; JJ++) {
                let latitude = parseFloat(locationObj.locationLogs[JJ].latitude)
                let longitude = parseFloat(locationObj.locationLogs[JJ].longitude)
                data.push({ lat: latitude, lng: longitude })
              }
            }
            this.locationArr.push(data)
          }
          setTimeout(() => {
            this.setBound()
          }, 100)
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    // displayJobTrackingPathTechnicion () {
    //   var infowindow = new google.maps.InfoWindow()
    //   let map = this.$refs.gmap.$mapObject
    //   let tempArr = []
    //   for (let I = 0; I < this.userLocation.length; I++) {
    //     for (let J = 0; J < this.userLocation[I].locationList.length; J++) {
    //       var tempObj = {
    //         lat: parseFloat(this.userLocation[I].locationList[J].latitude),
    //         lng: parseFloat(this.userLocation[I].locationList[J].longitude)
    //       }
    //       tempArr.push(tempObj)
    //       let mapMarker = new google.maps.Marker({
    //       position: new google.maps.LatLng(
    //         this.userLocation[I].locationList[J].latitude, this.userLocation[I].locationList[J].longitude),
    //         icon: {
    //           path: google.maps.SymbolPath.CIRCLE,
    //           scale: 4,
    //           fillColor: this.userLocation[I].displayColor,
    //           fillOpacity: 0.8,
    //           strokeWeight: 0.2
    //         },
    //         map: map
    //       })
    //       let test = moment(this.userLocation[I].locationList[J].addedDate).format('Y-M-D hh:mm:ss a')
    //       google.maps.event.addListener(mapMarker, 'mouseover', (function (mapMarker) {
    //         return function () {
    //           infowindow.setContent(test.toString())
    //           infowindow.open(map, mapMarker)
    //         }
    //       })(mapMarker, I))
    //       google.maps.event.addListener(mapMarker, 'mouseout', (function () {
    //         return function () {
    //           infowindow.close()
    //         }
    //       })(mapMarker, I))
    //       this.pathMarker.push(mapMarker)
    //         let temp123 = new google.maps.Polyline({
    //           map: map,
    //           path: tempArr,
    //           strokeColor: this.userLocation[I].displayColor,
    //           strokeOpacity: 0.9,
    //           strokeWeight: 3
    //         })
    //         this.flightPath.push(temp123)
    //     }
    //   }
    //   this.setBound()
    // },
    setBound() {
      this.$refs.gmap.$mapPromise.then((map) => {
        let fitBoud = false
        const bounds = new google.maps.LatLngBounds()
        for (let m of this.locationArr) {
          for (let index = 0; index < m.length; index++) {
            fitBoud = true
            let position = { lat: m[index].lat, lng: m[index].lng }
            bounds.extend(position)
          }
        }
        if (fitBoud === true) {
          map.fitBounds(bounds)
        }
      })
    },
    getCustDetails() {
      this.totalTimeValue = 0
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.specificShiftDetail(
        parseInt(this.$route.params.shiftId),
        (response) => {
          this.custDetails = response.Data;
          this.TotalBreak = 0
          console.log('this.custDetails.breakList', this.custDetails.breakList)
          if (this.custDetails.breakList.length > 0) {
          console.log('this.custDetails.breakList', this.custDetails.breakList)
            for (let index = 0; index < this.custDetails.breakList.length; index++) {
              this.TotalBreak += this.custDetails.breakList[index].totalTimeInMin
              if (this.custDetails.breakList[index].breakStartTime !== '' && this.custDetails.breakList[index].breakEndTime !== '') {
                this.custDetails.breakList[index].displayText = getDisplayText(this.custDetails.breakList[index].breakStartTime, this.custDetails.breakList[index].breakEndTime)
              } else {
                this.custDetails.breakList[index].displayText = '--'
              }
              if (this.custDetails.breakList[index].breakTitle === this.custDetails.breakTitle && this.custDetails.breakList[index].timeSheetBreakId === this.custDetails.timeSheetBreakId) {
                this.breakTitleVal = 'In ' + this.custDetails.breakTitle + ' From ' + getDisplayText(this.custDetails.breakList[index].breakStartTime, '')
                this.breakNoteVal = this.custDetails.breakList[index].notes
                this.showBreakIsOn = true
              } else [
                this.showBreakIsOn = false
              ]
            }
          }
          if (this.custDetails.totalTimeInMin === 0 && this.custDetails.shiftEndTime === '') {
            var today = new Date();
            var diffMs = (new Date(this.custDetails.shiftStartTime) - today);
            var diffMins = Math.abs(Math.round(((diffMs % 86400000) % 3600000) / 60000));
            console.log('diffMins', diffMins)
            this.totalTimeValue = diffMins - this.TotalBreak
          } else {
            this.totalTimeValue = this.custDetails.totalTimeInMin - this.TotalBreak
          }
          if (this.custDetails.shiftEndTime === '') {
            this.displayText = 'Clocked In at ' + getDisplayText(this.custDetails.shiftStartTime, '')
          } else {
            this.displayText = getDisplayText(this.custDetails.shiftStartTime, this.custDetails.shiftEndTime)
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    deleteShift() {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...", });
      MyJobApp.specificShiftDelete(
        parseInt(this.$route.params.shiftId),
        (response) => {
          this.$router.push({name: 'shift'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    showFullImage(data) {
      this.fileObject = data
      this.shoFullImage = true
    },
    dateFilter(dateTime, value) {
      let checkDate = new Date(dateTime)
      let timeValue = moment(checkDate, 'ddd DD-MMM-YYYY, hh:mm a').format('hh:mm a')
      let todayDate = new Date().setHours(0, 0, 0, 0)
      var fullDate = moment(dateTime).format('MMM DD, ')
      let dayName = moment(dateTime).format('ddd')
      dateTime = new Date(dateTime).toUTCString()
      let requestDt = dateTime
      requestDt = new Date(requestDt).setHours(0, 0, 0, 0)
      if (value === 'scheduleDate') {
        return fullDate + ' ' + dayName
      } else if (value === 'scheduleTime') {
        return timeValue
      } else {
        if (moment(todayDate).diff(requestDt, 'days') === 0) {
          return 'Today ' + timeValue
        }
        let difDay = moment(todayDate).diff(requestDt, 'days')
        if (difDay > 1) {
          return fullDate + ' ' + timeValue
        }
        if (difDay === 1) {
          return 'Yesterday ' + timeValue
        }
      }
    },
  },
  beforeDestroy() {
    this.$root.$off("setNewTabActive");
    this.$root.$off("fullScreenSelfies");
    this.$root.$off("confirmBoxHandler");
  },
};
</script>
<style scoped>.scroll-img-view {
  height: 104px;
}

.attachment-card {
  display: flex;
  align-items: center;
  position: relative;
}</style>